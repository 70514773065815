document.addEventListener("DOMContentLoaded", function() {
  const people01 = document.querySelector('.p-home-hero__people.--01');
  const people02 = document.querySelector('.p-home-hero__people.--02');
  const copy01 = document.querySelector('.p-home-hero__copy01');
  const people03 = document.querySelector('.p-home-hero__people.--03');
  const people04 = document.querySelector('.p-home-hero__people.--04');
  const fadeInUp = document.querySelectorAll('.js-fadeInUp');

  // フェードアップの順番を設定
  if (people01) {
    setTimeout(() => {
      people01.classList.add('is-fadeInUp');
    }, 0);
  }

  if (people02) {
    setTimeout(() => {
      people02.classList.add('is-fadeInUp');
    }, 200);
  }

  if (copy01) {
    setTimeout(() => {
      copy01.classList.add('is-fadeInUp');
    }, 600);
  }

  if (people03) {
    setTimeout(() => {
      people03.classList.add('is-fadeInUp');
    }, 1000);
  }

  if (people04) {
    setTimeout(() => {
      people04.classList.add('is-fadeInUp');
    }, 1200);
  }

  function checkVisibility() {
    fadeInUp.forEach(element => {
      const rect = element.getBoundingClientRect();
      if (rect.top <= window.innerHeight && rect.bottom >= 0) {
        element.classList.add('is-fadeInUp');
      }
    });
  }

  // 初回チェック
  checkVisibility();

  // スクロールイベントでチェック
  window.addEventListener('scroll', checkVisibility);
});
