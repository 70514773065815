import Swiper from "swiper/bundle";
import "swiper/css/bundle";

const swiper = new Swiper(".mySwiper", {
  slidesPerView: 1.5,
  centeredSlides: true,
  spaceBetween: 10,
  // loop: true,
  // loopAdditionalSlides: 1,
  keyboard: {
    enabled: true,
  },
  breakpoints: {
    1024: {
      spaceBetween: 40,
    },
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});
