import Simplemodal from '../../plugins/simplemodal.js';

const fullScreenMenu = new Simplemodal({
  trigger : "data-fullScreenMenu-trigger",
  clickOutSideClose : true,
  backFixed:false
})

// // アンカーリンク対応
// document.querySelectorAll('.fullScreenMenu-content__nav__list__item__link').forEach(function(link) {
//   link.addEventListener('click', function() {
//       let menu = document.getElementById('js-fullScreenMenu-content');
//       let button = document.querySelector('.fullScreenMenu-button');
//       if (menu && button) {
//           // メニューを閉じるためのコードをここに書く
//           // 例えば、メニューがCSSクラスを使用して開閉している場合、そのクラスを削除する
//           menu.classList.remove('is-open');
//           // ボタンの.is-openクラスを削除し、.is-closeクラスを追加
//           button.classList.remove('is-open');
//           button.classList.add('is-close');
//           // ボタンのaria-expanded属性をfalseに設定
//           button.setAttribute('aria-expanded', 'false');
//       }
//   });
// });